const SpotifyAccount = {
    state: {
        spotifyAccount: {}
    },
    mutations: {
        Spotify_User(state, spotifyAccount) {
            state.spotifyAccount = spotifyAccount;
        }
    },
    getters: {
        spotifyAccount: state => {
            return state.spotifyAccount;
        }
    }
}

export {
    SpotifyAccount
}