<template>
  <div v-if="hasTrackData">
    <div v-bind:class="{'background':true, 'playing':isPlaying}">
      <div class="background-inner" v-bind:style="backgroundStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Background",
  created() {},
  data() {
    return {
      totalPages: 5,
      currentPage: 3
    };
  },
  computed: {
    hasTrackData() {
      return this.$store.getters.hasSBTrack;
    },
    currentSBState() {
      return this.$store.getters.spotiBoxState;
    },
    backgroundStyle() {
      return {
        backgroundImage:
          "url(" +
          this.currentSBState.currentSpotiBoxTrack.spotifyTrack.album.images[0]
            .url +
          ")"
      };
    },
    isPlaying() {
      return !this.$store.getters.isPaused;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.background {
  position: fixed;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .background-inner {
    width: 100%;
    height: 100%;
    margin-top: 0%;
    margin-left: 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    opacity: 0.7;
    //animation: bganim 360s linear 1000ms infinite;
    transition: transform 10s ease-in-out;
    will-change: transform;

    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }
}

@keyframes bganim {
  0% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.5) rotate(22deg);
  }
  50% {
    transform: scale(2) rotate(45deg);
  }
  75% {
    transform: scale(1.5) rotate(22deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
</style>