import {
    MasterPlayer
} from "@/systems/MasterPlayer"

const Account = {
    state: {
        status: false,
        name: '',
        email: '',
        spotifyTokens: {},
        data: {},
        library: []
    },
    mutations: {
        AUTH(state, data) {
            state.data = data;
            state.email = data.ad[3].split(":")[1].trim();
            state.name = data.ad[1].split(":")[1].trim();
            state.spotifyTokens = data.spotifyTokens;
            if (state.name != '') {
                state.status = true;
            }
        },
        Library(state, data) {
            state.library = data;
        }
    },
    getters: {
        loggedIn: state => {
            return state.status
        },
        username: state => {
            return state.name
        },
        spotifyTokens: state => {
            return state.spotifyTokens
        },
        library: state => {
            return state.library
        }
    },
    actions: {
        getAuth({
            commit
        }) {
            fetch('/api/auth').then(response => response.json())
            .then((response) => {
                //console.log("AUTH",response);
                commit("AUTH", response);
            })
            .catch((error) => {
                console.warn("auth",error.statusText)
            });
        },
        getAuthAndPlayTrack({commit}){
            
            fetch('/api/auth').then(response => response.json())
            .then((response) => {
                //console.log("AUTH",response);
                commit("AUTH", response);
                
                MasterPlayer().playNextTrack();
            })
            .catch((error) => {
                console.warn("auth",error.statusText)
            });
            
        },
        getLibrary({
            commit
        }) {
            fetch('/api/library').then(response => response.json())
            .then((response) => {
                commit("Library", response);
            })
            .catch((error) => {
                console.warn("library",error.statusText)
            });
        },
    }
}

export {
    Account
}