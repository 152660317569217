<template>
  <div v-if="hasPlaylist" class="playlist">
    <h2 class="white-text">
      Playlist
      <span class="count">{{currentPlaylist.length}} tracks</span>
    </h2>
    <div class="playlist-items">
      <div v-for="item in currentPlaylist" v-bind:key="item.id" class="white-text playlist-item">
        <div class="art">
          <img :src="item.spotifyData.album.images[1].url" />
        </div>
        <div class="details">
          <div class="track-name ">{{item.spotifyData.name}}</div>
          <div
            v-for="item in item.spotifyData.artists"
            v-bind:key="item.id"
            class="grey-text artistLink"
          >{{item.name}}</div>
        </div>
        <div class="requested-name orange-text text-darken-2">Chosen by {{item.requestedByName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Playlist",
  mounted: function() {},
  computed: {
    localState() {
      return this.$store.getters.localPlayActive;
    },
    hasPlaylist() {
      if (this.$store.getters.currentPlaylist.length > 0) {
        return true;
      }
      return false;
    },
    currentPlaylist() {
      return this.$store.getters.currentPlaylist;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.playlist-items {
  height: 153px;
  overflow: hidden;
}

.playlist-item {
  float: left;
  width: 150px;
  height: 153px;
  background: #000;
  overflow: hidden;
  padding: 3px;
  margin: 3px;
}

.details{
  position: relative;
    width: 150px;
    background: rgba(0, 0, 0, 0.85);
    margin-top: -150px;
    padding: 5px;
    height:150px;
}

.track-name{
  font-weight: strong;
  font-size:15px;
}

.requested-name{
  font-size:11px;
    position: relative;
    margin-top: -30px;
    padding: 5px;
}

.count {
  font-size: 15px;
}

.art {
  img {
    width: 100%;
  }
}
</style>