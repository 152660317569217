import Vue from 'vue'
import App from './App.vue'
import router from './systems/Router'
import store from './systems/Store'
import auth from './systems/Auth'

Vue.config.productionTip = false

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

auth.login().then(() =>
    init())
  .catch(() => {
    window.location = "/AzureAD/Account/Signin";
    //init();
  });

function init() {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}