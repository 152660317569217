 const WebSocket = {

   state: {
     id: ''
   },
   mutations: {
     StoreSocketID(state, value) {
       state.id = value;
     }
   },
   getters: {}
 }

 export {
   WebSocket
 }