import PlayerHub from "@/Hubs/Player"
import {
    MasterPlayer
} from "@/systems/MasterPlayer"


const LocalPlay = {

    state: {
        active: false,
        spotify: {},
        master: false
    },
    mutations: {
        LocalPlayerStatus(state, status) {
            state.active = status;
        },
        Spotify_State(state, spotifyState) {
            state.spotify = spotifyState;
        },
        SetMasterPlayer(state, value) {

            state.master = value;
            if (value) {
                PlayerHub.setAsMaster();
                if (state.active) {
                    MasterPlayer().playNextTrack();
                }
            }
        },
        SetSpotiBoxTrack(state, track) {
            state.spotiBoxTrack = track;
        }
    },
    getters: {
        localPlayActive: state => {
            return state.active;
        },
        isMasterPlayer: state => {
            if (state.master) {
                return state.master;
            } else {
                return false;
            }
        }
    }
}

window.playState = LocalPlay;

export {
    LocalPlay
}