import {
    millisToMinutesAndSeconds
} from '../utils/Converters';


const SpotiBoxState = {
    state: {
        currentTrack: undefined,
        position: 0,
        duration: 0,
        paused: false,
        currentSpotiBoxTrack: undefined,
        playlist: [],
        initialLoad:0
    },
    mutations: {
        update(state, data) {
            if (typeof (data.track_window) == "object") {
                if(state != null){
                    state.currentTrack = data.track_window.current_track;
                    state.position = data.position;
                    state.duration = data.duration;
                    state.paused = data.paused;
                }
            }
        },
        trackPosition(state, masterstate) {
            state.position = masterstate.position;
            state.paused = masterstate.paused;
            if (typeof (state.currentTrack.spotifyTrack) == "object") {
                state.currentTrack.spotifyTrack.position = masterstate.position;
            }
        },
        updateSpotiBoxData(state, data) {

            state.currentSpotiBoxTrack = JSON.parse(data);
        },
        updatePlaylist(state, data) {

            data.forEach(function (entry) {
                entry.spotifyData = JSON.parse(entry.spotifyData);
            });

            state.playlist = data;
        },
        startApp(state, data){
            state.initialLoad = data;
        }
        
    },
    actions: {
        init({
            commit
        }) {
            var time  = new Date().getTime();
            commit("startApp", time);
        },
    },
    getters: {
        initTime: state => {
            return state.initialLoad;
        },
        spotiBoxState: state => {
            return state;
        },
        currentTrack: state => {
            return state.currentSpotiBoxTrack
        },
        currentPlaylist: state => {
            return state.playlist
        },
        hasCurrentTrack: state => {
            if (state.currentTrack !== undefined) {
                return true;
            }
            return false;
        },
        hasSBTrack: state => {
            if (state.currentSpotiBoxTrack !== undefined && state.currentSpotiBoxTrack !== null) {
                return true;
            }
            return false;
        },
        currentProgress: state => {
            return millisToMinutesAndSeconds(state.position);
        },
        currentDuration: state => {
            return millisToMinutesAndSeconds(state.currentSpotiBoxTrack.spotifyTrack.duration_ms);
        },
        currentPercentage: state => {
            return ((state.position / state.currentSpotiBoxTrack.spotifyTrack.duration_ms) * 100) + "%";
        },
        isPaused: state => {
            return state.paused;
        }
    }
}

export {
    SpotiBoxState
}