<template>
  <div id="app" >
    <background />
    <div class="mainApp ">
      <pageheader />
      <template v-if="$route.matched.length">
        <router-view></router-view>
      </template>
      <template v-else>
        <div class="container white-text">
          <h2>This page does not exist</h2>
          <p>You are logged {{ loggedIn ? 'in' : 'out' }}</p>
        </div>
      </template>
      <footer v-if="loggedIn">
        <playerBar />
      </footer>
    </div>
  </div>
</template>

<script>
import pageheader from "@/components/Header.vue";
import playerBar from "@/components/PlayerBar.vue";
import background from "@/components/Background.vue";


export default {
  components: {
    pageheader,
    playerBar,
    background
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    }
  },
  methods: {

  },

  created() {
    var _this = this;
    _this.$store.dispatch("init");
    _this.$store.dispatch("getAuth");
  },

};
</script>

<style lang="scss">
body {
  font-family: raleway !important;
  
}

.mainApp {
  position: absolute;
  overflow:auto;
  padding-top:100px;
  padding-bottom:140px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.brand-logo {
  padding-left: 20px !important;
  font-size: 17px !important;
}

.nav-wrapper ul a {
  transition: color 0.2s ease-in-out, border 0.4s ease-in-out;
  border-bottom: 2px transparent solid;
}

.nav-wrapper ul a.active {
  color: #26a69a;
  border-bottom: 2px #26a69a solid;
}
</style>
