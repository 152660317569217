import {
    SpotiBoxApi
} from "@/systems/SpotiBoxApi"
import store from './Store'
import PlayerHub from "@/Hubs/Player"
import {
    spotifyAPI
} from "@/systems/Spotify"


const MasterPlayer = function () {
    var gettingNextTrack = false;
    return {
        update: function () {
            if (store.getters.isMasterPlayer) {
                window.spotifyPlayer.getCurrentState().then(state => {
                    if (state != null) {
                        var playerState = {
                            uri: state.track_window.current_track.uri,
                            position: state.position,
                            duration: state.track_window.current_track.duration,
                            paused: state.paused
                        }
                        PlayerHub.updateMasterStatus(playerState);
                    }
                });
            }
        },
        playNextTrack() {
            if (!gettingNextTrack && store.getters.isMasterPlayer) {

                var initTime = store.getters.initTime;
                var rebootInterval = 3600000 * 6;
                var timeNow = new Date().getTime();
                var rebootCheck = timeNow - rebootInterval;

                if(initTime < rebootCheck ){
                    window.location = "/sbmaster";
                }
                else{
                    window.spotifyPlayer.pause();
                    gettingNextTrack = true;
                    //store.dispatch("getAuth");
                    SpotiBoxApi().master.getNextTrack().then(track => {
                        spotifyAPI().player.playTrack(track);
                        
                        gettingNextTrack = false;
                    });
                }
            }
        }
    }
}

export {
    MasterPlayer
}