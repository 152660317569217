
const SpotiBoxApi = function () {

    var lockMaster = false;
    var baseURL = "/api/";
    var fetchOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    return {
        master: {

            getNextTrack: function () {

                return new Promise(function (resolve, reject) {

                    if (!lockMaster) {
                        lockMaster = true
                        fetch(baseURL + 'player/getNextTrack', fetchOptions)
                        .then(response => response.json())
                        .then(response => {
                            lockMaster = false;
                            resolve(response)
                        }).catch((error) => {
                            reject(error);
                        });
                    }
                });
            },
            updateSpotifyData: function (spotifydata,id) {
                
                var postData = {
                    Json: JSON.stringify(spotifydata)
                }

                var data = {
                    ...fetchOptions
                };
                data.method = "POST";
                data.body = JSON.stringify(postData);

                fetch(baseURL + 'library/updatetrack/' + id, data)
                .then(response => response.json())
                .catch((e) => {
                    console.error("spotibox-api-updateSpotifyData",e);
                });
            }
            
        }

    }
}

export {
    SpotiBoxApi
}