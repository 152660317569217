

const SearchResults = {
    state: {
        results: {},
        query: '',
        filter: ''
    },
    mutations: {
        StoreSearchResults(state, results) {
            state.results = results;
        },
        StoreSearchQuery(state, query){
            state.query = query;
        },
        StoreSearchFilter(state, filter){
            state.filter = filter;
        }

    },
    getters: {
        searchResults: state => {
            return state.results
        },
        searchQuery: state => {
            return state.query
        },
        searchFilter: state => {
            return state.filter
        },
    },
    actions: {
       
    }
}

export {
    SearchResults
}