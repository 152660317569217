
<template>
  <nav class="trans-black">
    <div class="nav-wrapper ">
      <ul id="nav-mobile" class="right hide-on-med-and-down">
        <li>
          <span class="username teal-text text-lighter-2 right" v-if="loggedIn">{{username}}</span>
        </li>
        <li>
          <a v-if="!loggedIn" href="/AzureAD/Account/Signin" class="btn-floating pulse">
            <i class="material-icons">power</i>
          </a>
        </li>
        <li>
          <a v-if="loggedIn" href="/AzureAD/Account/Signout" class="btn-floating orange darken-3">
            <i class="material-icons">power_off</i>
          </a>
        </li>
      </ul>

      <ul id="nav-mobile" class="left hide-on-med-and-down">
        <li>
          <a href="#" class="brand-logo orange-text">
            <img class="logo" src="/logosmall.png" />
            <div class="version grey-text text-lighten-1">v4.1 -beta</div>
          </a>
        </li>
        <li>
          <router-link exact-active-class="active" to="/">Home</router-link>
        </li>
        <li>
          <router-link v-if="loggedIn" exact-active-class="active" to="/library">My Library</router-link>
        </li>
        <li>
          <router-link v-if="loggedIn" exact-active-class="active" to="/search">Search</router-link>
        </li>
        <li v-if="username.toLowerCase().indexOf('spotibox') > -1">
          <router-link v-if="loggedIn" exact-active-class="active" to="/SbMaster">Master</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",

  created() {},
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    username() {
      return this.$store.getters.username;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.username {
  margin-left: 200px;
}
nav {
  position: fixed;
  top:0;
  background-color: transparent !important;
  padding-right:20px;
  padding-left:20px;
  z-index: 900;
}
.trans-black {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.version {
  position: absolute;
  //margin-left:-2px;
  font-size: 9px;
  margin-top: -8px;
  top: 26px;
}

.brand-logo {
  position: relative;
  padding-right: 50px !important;
  font-size: 20px !important;
}

.logo {
  width: 100px;
  margin-left: -2px;
  margin-top: 15px;
}
</style>