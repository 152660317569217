<template>
  <div v-if="hasTrackData" class="nowPlaying trans-black">
    <div>
      <h2 class="white-text">Now Playing</h2>
      <div class="art">
        <img :src="currentSBState.currentSpotiBoxTrack.spotifyTrack.album.images[0].url" />
      </div>
      <div class="details">
        <div class="white-text">{{currentSBState.currentSpotiBoxTrack.spotifyTrack.name}}</div>
        <div class="artists">
          <div
            v-for="item in currentSBState.currentSpotiBoxTrack.spotifyTrack.artists"
            v-bind:key="item.id"
            class="grey-text artistLink"
          >{{item.name}}</div>
        </div>
        <div class="likes">
          <div class="like up">
            <div class="btn btn-floating blue tiny">
              <i class="material-icons">thumb_up</i>
            </div>
            {{currentSBState.currentSpotiBoxTrack.track.likes}}
          </div>
          <div class="like down">
            <div class="btn btn-floating red darken-3 tiny">
              <i class="material-icons">thumb_down</i>
            </div>
            {{currentSBState.currentSpotiBoxTrack.track.dislikes}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NowPlaying",
  mounted: function() {},
  computed: {
    localState() {
      return this.$store.getters.localPlayActive;
    },
    currentSBState() {
      return this.$store.getters.spotiBoxState;
    },
    hasTrackData() {
      return this.$store.getters.hasSBTrack;
    },
    isMasterPlayer() {
      return this.$store.getters.isMasterPlayer;
    },
    currentProgess() {
      return this.$store.getters.currentProgress;
    },
    currentDuration() {
      return this.$store.getters.currentDuration;
    },
    currentPercentage() {
      return this.$store.getters.currentPercentage;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nowPlaying {
  width: 100%;
  padding: 15px;
  bottom: 0;
  min-height: 100px;
  overflow: auto;

  h2 {
    margin-top: 0;
  }
}

.art {
  float: left;
  width: 40%;
  padding: 10px;
  img {
    width: 100%;
    outline: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.details {
  float: left;
  font-size: 35px;
  width: 60%;
}

.likes {
  //position: absolute;
  //bottom: 10px;
  //width: 100%;
  //height: 20px;

  .like {
    display: inline-block;
    margin-right: 20px;
    font-size: 0.6em;
    color:#fff;
    .btn-floating {
      margin-right: 5px;
      margin-left: 5px;
      width:20px;
      height:20px;
      font-size: 0.6em;
      line-height: 20px;
      .material-icons{
        font-size: 0.7em;
        line-height: 20px;
      }
    }
  }
}

.artists {
  //display:inline-block;
  line-height: 30px;
}

.artistLink {
  display: inline;
  font-size: 25px;
}

.artists .artistLink:after {
  content: ", ";
}

.artists .artistLink:last-child:after {
  content: "";
}

.trans-black {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>