<template>
  <div class="home container">

    <div v-if="loggedIn" class="white-text">
      <nowplaying class="nowPlaying"/>
      <playlist />
    </div>
    <div v-else class="white-text">
      <h2 class="white-text">SpotiBox</h2>

      <p>Please login to access SpotiBox</p>
    </div>
  </div>
</template>

<script>

import nowplaying from "@/components/NowPlaying"
import playlist from "@/components/Playlist"

export default {
  name: "home",
  components: {
    nowplaying,
    playlist
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    }
  }
};
</script>

<style scoped lang="scss">
  .nowPlaying{
    margin-top:40px;
  }
</style>
