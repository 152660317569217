var millisToMinutesAndSeconds = function (millis) {
    if(millis > 1000){
        millis -= 1000
    }
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export {
    millisToMinutesAndSeconds
}