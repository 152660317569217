import Vue from 'vue'
import Vuex from 'vuex'
import {Account} from "../Stores/Account"
import {SpotiBoxState} from "../Stores/SpotiBoxState"
import {LocalPlay} from "../Stores/LocalPlay"
import {SpotifyAccount} from "../Stores/SpotifyAccount"
import {WebSocket} from "../Stores/WebSocket"
import {SearchResults} from "../Stores/SearchResults"


Vue.use(Vuex)

const store = new Vuex.Store({

  modules: {
    localPlay: LocalPlay,
    account: Account,
    spotifyAccount: SpotifyAccount,
    webSocket: WebSocket,
    SpotiBoxState: SpotiBoxState,
    searchResults: SearchResults
  }
});

export default store