import store from './Store'
import {
    MasterPlayer
} from "@/systems/MasterPlayer"

import PlayerHub from '@/Hubs/Player'
import {
    SpotiBoxApi
} from '@/systems/SpotiBoxApi'

var player = {};
var forcePause = false;
var interval = "";
var playerTollerance = 5000;
var lastSyncAttempt = null;
var updateFrequency = 500;
var deviceID = "";
var playCheckInterval = "";
var playCount = 0;
var playerReloadTrackCount = 2;
var autoPlayTrack = null;
var gettingNewTrack = false;

export default {

    init: function () {
        window.onSpotifyWebPlaybackSDKReady = function () {};
    }
}.init();

const setupLocalPlayer = function () {

    window.onSpotifyWebPlaybackSDKReady = () => {
        var tokens = store.getters.spotifyTokens;

        const token = tokens.access_token;

        player = window.spotifyPlayer = new window.Spotify.Player({
            name: 'SpotiBox',
            getOAuthToken: cb => {
                cb(token);
            }
        });

        // Error handling
        player.addListener('initialization_error', ({
            message
        }) => {
            //store.dispatch("getAuth");
            //console.warn("e1", message);
        });
        player.addListener('authentication_error', ({
            message
        }) => {
            console.error("e2", message);
            if (store.getters.isMasterPlayer) {
                store.dispatch("getAuthAndPlayTrack");
            } else {
                store.dispatch("getAuth");
            }
        });
        player.addListener('account_error', ({
            message
        }) => {
            //store.dispatch("getAuth");
            //console.warn("e3", message);
        });
        player.addListener('playback_error', ({
            message
        }) => {
            //store.dispatch("getAuth");
            //MasterPlayer().playNextTrack();
            //console.warn("e4", message);
        });

        player.on('playback_error', ({
            message
        }) => {
            //console.error('Failed to perform playback', message);
        });

        // Playback status updates
        player.addListener('player_state_changed', spotifyState => {
            //console.log("playerState", spotifyState);
            //spotifyAPI().user.currentTrack();
            //MasterPlayer().update(spotifyState);
            //MasterPlayer().stateChanged(spotifyState);
            //store.commit('Spotify_State', spotifyState);

            //if(!forcePause && spotifyState.position){
            //    player.resume();
            //}else{
            //    player.pause();
            //}

            if (!store.getters.isMasterPlayer) {
                store.dispatch("getAuth");              
            }
            
            clearInterval(playCheckInterval);
            if (spotifyState != null && !spotifyState.paused) {
                playCheckInterval = setInterval(function () {
                    window.spotifyPlayer.getCurrentState()
                        .then((state) => {
                            //console.log("STS",state)
                            stateChanged(state);
                        })
                }, 500);
            }

        });

        // Ready

        if (!store.getters.isMasterPlayer) {
            setInterval(function(){
                store.dispatch("getAuth");
            }, 30000);
            
        }

        player.addListener('ready', ({
            device_id
        }) => {

            store.commit('LocalPlayerStatus', true);

            if (playCount > playerReloadTrackCount && autoPlayTrack != null && store.getters.isMasterPlayer) {
                spotifyAPI().player.playTrack(autoPlayTrack, 0);

            }

            playCount = 0;
            autoPlayTrack = null;

            //spotifyAPI().user.currentTrack();
            spotifyAPI().player.setAsPlayer(device_id);
            deviceID = device_id;
            interval = setInterval(function () {
                MasterPlayer().update()
            }, updateFrequency);

            if (store.getters.isMasterPlayer) {
                MasterPlayer().playNextTrack();
            }
        });

        // Not Ready
        player.addListener('not_ready', ({
            device_id
        }) => {
            //console.log('Device ID has gone offline', device_id);
            store.commit('LocalPlayerStatus', false);

            clearInterval(interval);
        });

        function stateChanged(state) {
            //console.log("state", state);
            if (store.getters.isMasterPlayer) {
                store.commit("update", state);
            }

            if (state.position > state.duration - 600 && store.getters.localPlayActive) {
                player.pause();
                if (store.getters.isMasterPlayer) {
                    store.dispatch("getAuthAndPlayTrack");
                }else{
                    window.spotifyPlayer.pause();
                    window.spotifyPlayer.disconnect();
                    clearInterval(interval);

                    setTimeout(setupLocalPlayer,1000);
                }
            }
        }

        // Connect to the player!
        player.connect();

        //spotifyAPI().user.getCurrent();
    };

    if (window.Spotify != undefined && (typeof window.onSpotifyWebPlaybackSDKReady !== 'undefined')) {
        window.onSpotifyWebPlaybackSDKReady();
    }
}

const disablePlayer = function () {
    window.spotifyPlayer.pause();
    window.spotifyPlayer.disconnect();
    clearInterval(interval);
    store.commit("Spotify_CurrentTrack", undefined);
    store.commit('LocalPlayerStatus', false);
    store.commit('SetMasterPlayer', false);
}


function CheckPlaying() {
    gettingNewTrack = false;
    if (store.getters.isMasterPlayer) {
        player.getCurrentState()
            .then(state => {
                if (state.paused && !forcePause) {
                    store.dispatch("getAuthAndPlayTrack");
                }
            });

    }
}

function CheckIfNewTrack(track) {

    return new Promise(function (resolve) {

        player.getCurrentState()
            .then(state => {
                if (state !== undefined && state !== null) {
                    var playingTrack = state.track_window.current_track.uri;
                    if (playingTrack.uri == track.uri) {
                        gettingNewTrack = false;
                        resolve(false);
                    }
                }
                resolve(true);
            })
    });
}

const spotifyAPI = function () {

    var tokens = store.getters.spotifyTokens;
    var baseURL = "https://api.spotify.com/v1/";
    var fetchOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + tokens.access_token,
        }
    }

    return {

        user: {
            getCurrent: function () {

                fetch(baseURL + 'me', fetchOptions)
                    .then(response => response.json())
                    .then((response) => {
                        store.commit("Spotify_User", response);
                        //console.log(response);
                    })
                    .catch((e) => {
                        //console.warn("api-user-getCurrent", e);
                    });
            },
            currentTrack: function () {

                fetch(baseURL + 'me/player/currently-playing?market=GB', fetchOptions)
                    .then(response => response.json())
                    .then((response) => {
                        store.commit("Spotify_CurrentTrack", response);
                    })
                    .catch((e) => {
                        //console.warn("api-user-currentTrack", e);
                    });
            }
        },
        player: {
            playTrack: function (track, position_ms) {
                if (!gettingNewTrack) {
                    gettingNewTrack = true;

                    if (position_ms === undefined) {
                        position_ms = 0;
                    }

                    var verified_track = {};

                    if (playCount > playerReloadTrackCount && autoPlayTrack == null) {
                        autoPlayTrack = track;
                        player.disconnect();
                        setupLocalPlayer();
                    }


                    fetch(baseURL + 'tracks/' + track.track.trackRef + "?market=from_token", fetchOptions)
                        .then(response => response.json())
                        .then((response) => {

                            verified_track = response;
                            track.spotifyTrack = verified_track;
                            CheckIfNewTrack(verified_track)
                                .then((isNew) => {
                                    if (isNew) {
                                        var requestData = {
                                            //uris: [verified_track.uri,verified_track.uri],
                                            context_uri: verified_track.album.uri,
                                            offset: {
                                                uri: verified_track.uri
                                            },
                                            position_ms: position_ms
                                        }

                                        var data = {
                                            ...fetchOptions
                                        };
                                        data.method = "PUT";
                                        data.body = JSON.stringify(requestData);

                                        fetch(baseURL + 'me/player/play?device_id=' + deviceID, data)
                                            .then((response) => {
                                                if (response.status == 403) {
                                                    //unable to play due to restricted or old uri (probably)
                                                    //do a search and try to find an alternative.
                                                    fetch(baseURL + 'search/?market=from_token&type=track&q=' + verified_track.artists[0].name + '%20' + verified_track.name, fetchOptions)
                                                        .then(response => response.json())
                                                        .then((response) => {

                                                            if (response.tracks.items.length > 0) {

                                                                verified_track = response.tracks.items[0];
                                                                track.spotifyTrack = verified_track;

                                                                CheckIfNewTrack(verified_track)
                                                                    .then((isNew) => {
                                                                        if (isNew) {
                                                                            var data = {
                                                                                ...fetchOptions
                                                                            };

                                                                            var requestData = {
                                                                                //uris: [verified_track.uri,verified_track.uri],           
                                                                                context_uri: verified_track.album.uri,
                                                                                offset: {
                                                                                    uri: verified_track.uri
                                                                                },
                                                                                position_ms: position_ms
                                                                            }

                                                                            data.method = "PUT";
                                                                            data.body = JSON.stringify(requestData);

                                                                            fetch(baseURL + 'me/player/play?device_id=' + deviceID, data);

                                                                            PlayerHub.UpdateCurrentlyPlaying(track);
                                                                            SpotiBoxApi().master.updateSpotifyData(verified_track, track.track.id);

                                                                            playCount++;

                                                                            setTimeout(CheckPlaying, 40000);

                                                                            gettingNewTrack = false;
                                                                        }

                                                                    })
                                                            }
                                                        });

                                                } else {

                                                    playCount++;
                                                    PlayerHub.UpdateCurrentlyPlaying(track);
                                                    setTimeout(CheckPlaying, 4000);
                                                    gettingNewTrack = false;
                                                    //gettingNewTrack = false;
                                                }
                                            })
                                            .catch((e) => {
                                                //console.warn("api-player-playTrack", e);
                                            });
                                    }
                                })
                        })

                }
            },
            setAsPlayer: function (playerID) {

                var requestData = {
                    device_ids: []
                }

                requestData.device_ids.push(playerID);

                var data = {
                    ...fetchOptions
                };
                data.method = "PUT";
                data.body = JSON.stringify(requestData);

                fetch(baseURL + 'me/player', data)
                    .catch((e) => {
                        //console.error("api-player-setAsPlayer", e);
                    });
            },

            syncMaster: function (masterState) {

                var requestData = {
                    uris: [masterState.uri],
                    position_ms: masterState.position
                }
                if (!store.getters.isMasterPlayer) {
                    if (!masterState.paused) {

                        if (store.state.localPlay.active && !store.state.localPlay.master) {
                            window.spotifyPlayer.getCurrentState().then(state => {

                                if ((state != null && state.track_window.current_track.uri != masterState.uri) || (state.position < masterState.position - playerTollerance || state.position > masterState.position + playerTollerance)) {

                                    var ts = new Date().getTime();

                                    if (lastSyncAttempt == null || lastSyncAttempt < (ts - (playerTollerance))) {

                                        lastSyncAttempt = ts;

                                        var data = {
                                            ...fetchOptions
                                        };
                                        data.method = "PUT";
                                        data.body = JSON.stringify(requestData);

                                        fetch(baseURL + 'me/player/play', data)
                                            .catch((e) => {
                                                //console.error("api-player-play", e);
                                            });
                                    } 

                                }
                            })
                        }
                    } else {
                        var data = {
                            ...fetchOptions
                        };
                        data.method = "PUT";
                        fetch(baseURL + 'me/player/pause', data)

                            .catch((e) => {
                                //console.error("api-player-pause", e);
                            });
                    }
                }
            }
        },
        search: function (query, type) {

            return new Promise(function (resolve, reject) {

                fetch(baseURL + 'search?market=GB&limit=50&q=' + query + "&type=" + type, fetchOptions)
                    .then(response => response.json())
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });

        }
    }
}



export {
    setupLocalPlayer,
    disablePlayer,
    spotifyAPI
}