import Vue from "vue"
import store from '@/systems/Store'
import {spotifyAPI} from '@/systems/Spotify'

const signalR = require("@microsoft/signalr");
const connection = new signalR.HubConnectionBuilder().withUrl("/hubs/player").build();

const PlayerHub = new Vue({
  Id: "",
  methods: {
    updateMasterStatus(data) {
      connection.invoke("UpdateMasterStatus", JSON.stringify(data));
    },
    UpdateCurrentlyPlaying(data) {
      connection.invoke("UpdateCurrentlyPlaying", JSON.stringify(data));
    },
    setAsMaster() {
      connection.invoke("PromoteToMaster");
    },
    send(message) {

      connection.invoke("SendMessage", message);
    },
    register() {
      connection.invoke("Register");
      
    },
    addToPlaylist(track){
     
      if(typeof(track) === "object"){
        connection.invoke("addSpotifyTrackToPlaylist", JSON.stringify(track));
      }else{
        connection.invoke("addToPlaylist", track);
      }
    },
    skip() {
      connection.invoke("SkipTrack");
    }
  },
  created: function () {
    connection.start().then(function () {
      PlayerHub.register();
    }).catch(function (err) {
      return console.error(err.toString());
    });
  }
})

connection.on("StoreId", function (id) {
  PlayerHub.Id = id;
  
})

connection.on("ConfirmMaster", function (id) {
  if(PlayerHub.Id == id){
      console.log("do nothing");
  }else{

    store.commit('SetMasterPlayer', false);
  }
});

connection.on("MasterState", function (masterData) {
    var data = JSON.parse(masterData);
    spotifyAPI().player.syncMaster(data);
    store.commit('trackPosition', data);
    
});

connection.on("CurrentlyPlaying", function (currentTrack) {
  store.commit('updateSpotiBoxData', currentTrack);
});

connection.on("SkipTrack", function () {
 
  store.dispatch("getAuthAndPlayTrack");
});


connection.on("UpdatePlaylist", function (playlist) {
  store.commit('updatePlaylist', playlist);
});

export default PlayerHub