<template>
  <div class="playerBar trans-black">
    <div v-if="hasSBData">
      <div class="progressBar">
        <div
          class="inner"
          v-bind:class="{'teal':!isMasterPlayer, 'red':isMasterPlayer}"
          :style="{width: currentPercentage}"
        ></div>
      </div>
      <div class="art">
        <img :src="currentSBState.currentSpotiBoxTrack.spotifyTrack.album.images[0].url" /> 
      </div>
      <div class="details">
        <div class="white-text trackName">{{currentSBState.currentSpotiBoxTrack.spotifyTrack.name}}</div>
        <div class="time grey-text">{{currentProgess}} - {{currentDuration}}</div>
        <div class="artists">
          <div
            v-for="item in currentSBState.currentSpotiBoxTrack.spotifyTrack.artists"
            v-bind:key="item.id"
            class="grey-text artistLink"
          >{{item.name}}</div>
        </div>

        <div
          v-if="hasSBData"
          class="selection-info orange-text text-darken-2">
            <span v-if="!currentSBState.currentSpotiBoxTrack.playLog.wasPlaylist">Random selection from {{currentSBState.currentSpotiBoxTrack.user.firstName}}'s Library</span>
            <span v-if="currentSBState.currentSpotiBoxTrack.playLog.wasPlaylist">Chosen by {{currentSBState.currentSpotiBoxTrack.user.firstName}}</span>
          </div>
      </div>
    </div>
    <div v-else>
      <p class="nomaster white-text">No master player currently running</p>
    </div>

    <div class="controls">
      <div v-if="hasSBData">
        <a v-if="!isPlaying" class="btn-floating green" v-on:click="play">
          <i class="material-icons">play_arrow</i>
        </a>
        <a v-if="isPlaying" class="btn-floating teal waves-effect" v-on:click="pause">
          <i class="material-icons">pause</i>
        </a>
        <a class="btn-floating teal waves-effect" v-on:click="skipTrack">
          <i class="material-icons">skip_next</i>
        </a>

        <a v-if="!isMasterPlayer" class="btn-floating btn-small green waves-effect">
          <i class="material-icons">thumb_up</i>
        </a>
        <a v-if="!isMasterPlayer" class="btn-floating btn-small red waves-effect">
          <i class="material-icons">thumb_down</i>
        </a>
      </div>
    </div>
    <div v-if="localPlayerAvailible" class="localPlayer">
      <div v-if="localState">
        <a
          v-if="isMasterPlayer"
          v-on:click="toggleLocalPlayer"
          class="btn-floating btn-large red accent-4 pulse"
         
        >
          <i class="material-icons">headset</i>
        </a>
        <a
          v-if="!isMasterPlayer"
          v-on:click="toggleLocalPlayer"
          class="btn-floating btn-large orange accent-4 pulse waves-effect"
          
        >
          <i class="material-icons">headset</i>
        </a>
      </div>
      <div v-else>
        <a v-on:click="toggleLocalPlayer" class="btn-floating btn-large teal darken-3 waves-effect">
          <i class="material-icons">headset</i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { setupLocalPlayer, disablePlayer } from "../systems/Spotify";
import PlayerHub from "@/Hubs/Player"

export default {
  name: "PlayerBar",
  mounted: function() {},
  data: function() {
    return {
      msg: "",
      messageClass: "msgBar"
    };
  },
  computed: {
    localPlayerAvailible() {
      return true;
    },
    localState() {
      return this.$store.getters.localPlayActive;
    },
    currentSBState() {
      return this.$store.getters.spotiBoxState;
    },
    hasSBData() {
      return this.$store.getters.hasSBTrack;
    },
    isMasterPlayer() {
      return this.$store.getters.isMasterPlayer;
    },
    currentProgess() {
      return this.$store.getters.currentProgress;
    },
    currentDuration() {
      return this.$store.getters.currentDuration;
    },
    currentPercentage() {
      return this.$store.getters.currentPercentage;
    },
    isPlaying() {
      return !this.$store.getters.isPaused;
    }
  },
  methods: {
    toggleLocalPlayer: function() {
      var newState = !this.localState;
      if (newState) {
        setupLocalPlayer();
      } else {
        disablePlayer();
      }
    },
    skipTrack: function(){
      PlayerHub.skip();
    },
    play: function(){
      console.warn("not implemented yet, sorry");
    },
    pause: function(){
      console.warn("not implemented yet, sorry");
    },
    like: function(){
      console.warn("not implemented yet, sorry");
    },
    dislike: function(){
      console.warn("not implemented yet, sorry");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.playerBar {
  width: 100%;
  padding: 15px;
  position: fixed;
  bottom: 0;
  min-height: 115px;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 900;

  transition: background-color 0.4s;
  //background:#000;

  .progressBar {
    position: absolute;
    height: 3px;
    background: #000;
    width: 100%;
    top: -3px;
    left: 0px;
    transition: background-color 0.4s;

    .inner {
      //transition: 0.4s width linear;
      position: absolute;
      height: 3px;
    }
  }

  .nomaster {
    position: absolute;
  }

  .selection-info {
    margin-top:5px;
    font-size: 13px;
  }

  .controls {
    position: absolute;
    right: 200px;
    margin-top: 10px;
    bottom:10px;
    height:75px;

    a {
      margin-left: 15px;
    }
  }

  .trackName{
    display: inline-block;
  }

  .time {
    display:inline-block;
    font-size: 14px;
    //display: inline-block;
    margin-left: 15px;
    line-height: 10px;
  }
}

.localPlayer {
  position: absolute;
  right: 40px;
  bottom:10px;
  height:75px;
}

.messageBar {
  min-height: 40px;
  width: 100%;
  color: #000;
  position: fixed;
  bottom: 100px;

  .msgBar {
    background: transparent;
    transition: opacity 0.3s ease;
    opacity: 0;
    text-align: center;

    &.active {
      font-size: 14px;
      padding: 10px;
      background-color: #ffff00;
      opacity: 1;
    }
  }
}

.art {
  position:absolute;
  bottom:10px;
  width: 90px;
  transition: width 0.2s ease-in-out;
  img {
    outline: 1px solid rgba(255, 255, 255, 0.5);
    width:100%;
  }

  &:hover{
    width:600px;
  }
}

.details {
  margin-left:120px;
  font-size: 20px;
}

.artists {
  //display:inline-block;
  line-height: 20px;
}

.artistLink {
  display: inline;
  font-size: 14px;
}

.artists .artistLink:after {
  content: ", ";
}

.artists .artistLink:last-child:after {
  content: "";
}

.trans-black {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.trans-red {
  background-color: rgba(75, 4, 4, 0.9) !important;
}
</style>