import store from './Store'

export default {

  logout() {
    window.location = "/azureAD/account/logout"
  },

  isLoggedIn() {
    return store.getters.loggedIn;
  },

  login() {
    return new Promise(function (resolve, reject) {

      fetch('/api/auth').then(response => response.json())
      .then((response) => {
          store.commit("AUTH", response);
          resolve(response)
      })
      .catch((error) => {
          //window.location = "/api/auth";
          reject(error);
      });
        

    });
  }

}