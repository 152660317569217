import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import auth from './Auth'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/library',
      name: 'library',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "library" */ '@/views/Library.vue'),
      beforeEnter: requireAuth
      },
      {
        path: '/search', 
        name: 'search', 
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
        beforeEnter: requireAuth
      },
      {
        path: '/search/:query', 
        name: 'search_withquery', 
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
        beforeEnter: requireAuth
      },
      {
          path: '/sbmaster',
          name: 'sbmaster',
          component: () => import(/* webpackChunkName: "sbmaster" */ '@/views/SbMaster.vue'),
          beforeEnter: requireAuth
      },
    { path: '/logout',
      beforeEnter (to, from, next) {
        auth.logout()
        next('/')
      }
    }
  ]
})

function requireAuth (to, from, next) {
  if (!auth.isLoggedIn()) {
    next({
      path: '/login',
      query: { redirectpath: to.fullPath }
    })
  } else {
    next()
  }
}
